import logo from './BeGreenLogo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Developing
        </p>
        <a
          className="App-link"
          href="mailto:info@begreen.it"
          target="_blank"
          rel="noopener noreferrer"
        >
          for information, drop an email
        </a>
      </header>
    </div>
  );
}

export default App;
